import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage(props: {}) {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js";
        script.async = true;
        document.body.appendChild(script);
        document.body.className = "page-contact"
        return () => {
          document.body.removeChild(script);
        }
    }, []);
    
    return (
        <Layout contentClassName="container">
           <SEO title="Contactez l'équipe de Shift Your Job" 
           description="Une question? Une suggestion ? N'hésitez pas à nous contacter pour la partager avec nous. Nous serons ravis d'y répondre dans les plus brefs délais"
           gtmPageType="contact" 
           gtmPageName="contact"/>
            <div className="iframe-container mx-2 pt-6 md:mx-auto lg:max-w-2xl">
                <iframe className="relative airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrTMKflEgoqDmSgV?backgroundColor=cyan" frameBorder="0" width="100%" height="957" style={{background: "transparent"}}></iframe>
            </div>
        </Layout>
    )
}


    
export default ContactPage
